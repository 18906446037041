.modal-confirmation{
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    
    
}

.modal-content{
    display:flex;
    color:#fff;
    background-color: var(--main-lighter-color);
    border-radius: 20px;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 300px; /* Could be more or less, depending on screen size */
}

.modal-video{
    color:#fff;
    border-radius: 20px;
    margin: 5% auto; /* 15% from the top and centered */
}

.modal-prom{
    display:flex;
    color:#fff;
    background-color:var(--main-color);
    border-radius: 10px;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 0px;
    border: none;
    width: 500px; /* Could be more or less, depending on screen size */
}

.modal-prom .modal-header{
    background-color: var(--main-color);
    border: none;
    border-radius: 10px 10px 0px 0px;
}

.modal-prom .modal-body{
    display:flex;
    flex-direction:column;
    align-items:center;
    color: var(--main-color);
}

.modal-prom .main-btn{
    background-color: var(--main-link-button-color);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}
.modal-prom .close-icon{
    width: 100%;
    text-align: end;
    margin-bottom: 0px;
    margin: 0px !important;
    font-size: 20px;
}
.modal-body {
    display:flex;
}

.countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .countdown-timer {
    font-size: 3rem;
    color: black
  }
  
  .countdown-label {
    font-size: 1rem;
    color: white;
    margin-top: 10px;
  }

  .countdown-bottom{
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: var(--main-link-button-color);
    color: #ffffff;
    padding: 0 10px;

  }
  .countdown-bottom:hover{
    background-color: var(--main-link-button-color);
    color: white;
    cursor: pointer;
  }


@media screen and (max-width: 600px) {
    .modal-prom{
        max-width: 300px;
    }
    .modal-prom .modal-header{
        font-size: 13px;
    }
}
    
