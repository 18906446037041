/* Vonage.css */
.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 600px;
    width: 800px;
    margin: auto;
    background-color: var(--main-color);
    border-radius: 6px;
}

.bottom-container{
  width: 800px;
  text-align: center;
  margin-top: 10px;
  margin: auto;
}

.big-video {
    width: 100%;
    height: 100vh;
}

.small-video {
    position: absolute;
    bottom: 10px;
    width: 120px;
    height: 90px;
}

.user-name {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 5px;
}

.calling-message{
  position: absolute;
  top: 20%;
}

#publisher {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    right: 10px;
    border-radius: 6px;
}

#subscriber {
    width: 100%; /* Take up full width */
    height: auto; /* Auto-adjust height based on aspect ratio */
    max-height: 100%;
    /* margin: 10px; */
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container-inside-message {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.vonage-button{
  border-radius: 6px;
  margin: auto;
}

.animated {
  animation: moveLeftBox 2s infinite both;
}



@keyframes moveLeftBox {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}


@media (max-width: 1276px){
 .container-video-message{
  flex-direction: column;
 }
}

@media (max-width: 768px){
  .video-container, .bottom-container{
    width: 500px;
  }
}

@media (max-width: 576px){
  .video-container, .bottom-container{
    width: 400px;
  }
}

@media (max-width: 480px){
  .video-container, .bottom-container{
    width: 300px;
  }
}