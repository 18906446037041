.intro-form{
    max-width: 420px;
    /* border: 3px solid #fff; */
    padding: 40px 50px;
    margin-bottom: 100px;
    border-radius: 50px;
    /* box-shadow: 0 2px 100px 0 #ffc107c4; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    float: right;
    margin-top: 90px;
    animation: boxShadowAnimation 3s linear infinite;
    background-color: var(--main-color);
}

.form-item, .form-item-double{
  text-align: left;
  /* margin-bottom: 12px; */
  margin-top:12px;
}

.form-item-double{
  display:flex;
  align-items: center;

}
.ages{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;

}

.form-item select, .form-item input, .form-item-double select, .form-item-double input{
  /* border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  outline: none;
  background: var(--second-color);
  color: white;
  border-radius: 100px; */
}

.form-item select option, .form-item-double select option{

  /* background: rgba(57, 25, 101, 0.8) !important; */
  background: var(--main-color) !important;
  border: none;
}

.form-item select{
  width: 70%;
  border-radius: 100px;
}
.form-item input{
  width: 70%;
}

.form-item-double select{
  width: 48%;
}

.form-item label, .form-item-double label{
  /* font-family: "Jost", sans-serif; */
  width: 30%;
}

.form-button{
  margin-top: 20px;
  border: none;
  padding: 13px;
  width: 100%;
  border-radius: 100px;

}
.intro-form p{
  max-width: 315px;
  line-height: 30px;
}


@media (max-width: 992px){
  .intro-form{
    /* margin: auto; */
  }
}

@media (max-width: 450px){

  .intro-form{
    padding: 20px 15px
  }
  /* .intro-form h3{
    font-size: 1.1em;
  }
  .intro-form p{
    font-size: 0.7rem;
  }
  .form-item , .form-item-double{
    font-size: 0.7rem;
  }
  .form-item p , .form-item-double p{
    font-size: 15px;
  } */
}

@media (max-width: 410px){

  .custom-photo{
    height: 0;
  }

  .intro-form h3{
    font-size: 1.1em;
  }
  .intro-form p{
    font-size: 0.7rem;
  }
  .form-item , .form-item-double{
    font-size: 0.7rem;
  }
  .form-item p , .form-item-double p{
    font-size: 15px;
  }
  
}

/* @media (max-width: 400px){
  .form-item , .form-item-double{
    font-size: 12px;
  }
}

@media (max-width: 350px){
  .form-item , .form-item-double{
    font-size: 10px;
  }
}
@media (max-width: 350px){
  .form-item , .form-item-double{
    font-size: 8px;
  }
} */
