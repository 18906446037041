
#cometchat_avchat_container * {
  box-sizing: initial;
}

*, ::after, ::before {
  box-sizing: border-box;
}


.tags li, ul li {
  list-style: none;
}

.bg-img {
  background: url(./images/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.a {
  text-decoration: none !important;
}

.f-white{
  color: white !important;
  /* font-family: "Jost", sans-serif; */
}

h2{
  font-size: 1.875rem !important;
}

h4{
  font-weight: 900 !important;
}

.bld-900{
  font-weight: 900 !important;
}


.navbar-header{
  position: sticky;
  top: 0 !important;
  z-index: 999;
}


.footer-section{
  /* position: absolute;
  bottom: 0;
  width: 100%; */
  margin-top: auto;
}

.icon-center{
  font-size: 20px;
  margin-bottom: 3px;
  margin-right: 5px;
}
.icon-center-header{
  font-size: 15px;
  margin-bottom: 3px;
  margin-right: 2px;
}


.red-error{
  border-color: red !important;
}
.error-message{
  text-align: start;
  margin-top: 5px;
  color: red;
}
.valid-message{
  text-align: start;
  margin-top: 5px;
  color: green;
}

.container-wrap{
  flex-wrap: wrap;
  display: flex;
}

.main-btn{
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  /* border-radius: 0.25rem; */
  border-radius: 100px;
  margin: auto;
}

.main-input, input, textarea{
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  outline: none;
  background: var(--second-color);
  color: white;
  border-radius: 6px;
}

/* input, textarea{
  background: var(--second-color);
} */

textarea{
  resize: none !important;
}

.main-select{
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  outline: none;
  background: var(--second-color);
  color: white;
}

.custom-file-upload{
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  margin: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.start-recording-button {
  font-size: 24px;
  background-color: #2196f3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.stop-recording-button {
  font-size: 24px;
  color: red;
  cursor: pointer;
}









.custom-photo{    
    /* background-repeat: no-repeat;
    background-size: cover;
    background-repeat:no-repeat;
    background-position:top right;
    height:130vh; 
    opacity: 0.80; */

    background-size: cover;
    height: 115vh;
    opacity: .9;
    
}

.custom-photo2{    
    background-image: url('./images/myphotos/home_photo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat:no-repeat;
    background-position:top right;
    height:130vh; 
    opacity: 0.75;
}

.full-photo{
  /* height: 100vh;
  width: 100%;
  object-fit: cover; */

}

.a-general{
  color: white;
}
.a-general:hover,.a-hover:hover{
  color:#C5A880;
}

.link-bold {
  color:#C5A880;
}


select option{
  background: var(--main-color) !important;
}

.br-radius50{
  border-radius:50% !important;
}

.online-circle, .offline-circle{
  font-size:12px;
  margin-bottom: 3px;
}
.online-circle{
  color: #00b300;
}

.offline-circle{
  color: grey;
}

.img-background{
  background-color:var(--second-color);
}

.mg-auto{
  margin: auto;
}


.main-shadow-box {
  box-shadow: 0px 2px 5px 0px #0F0F0F;
}

.icon-delete{
  right: 0px;
  top: 0px;
  border-radius: 0px 5px 0px 5px;
  font-size: 25px;
}

.centered-abs{
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
}



.pagination {
  display: inline-block;
}

.pagination a {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}


.toastify-notification{
  background-color:var(--main-link-button-color) !important;
  color:white !important;
}
.toastify-progressbar{
  background-color: white;
  background: '#EE0022'
}

.Toastify__progress-bar-theme--light{
  background:white !important;
}
/* .Toastify__close-button {
  color: white !important;
} */

.logo{
  width:200px;
}


.glass-effect:hover {
  background: linear-gradient(90deg,transparent,rgba(255,255,255,.2),transparent) 100%/200% no-repeat;
	transition: 0.3s;
	transition-delay: 0.3s;
}

.no-overflow{
  overflow: hidden;
}

.box{
  border-radius: 10px;
  background: var(--main-color);
  padding: 20px;

}

#cometchat_cancelcall {box-sizing: content-box}

.box p{
  font-size: 14px;
  font-weight: 400;
  /* font-family: Roboto,sans-serif; */
  color: #c3c6dd;
  line-height: 1.6;
}

.message-success{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@keyframes scaleUp {
  0% {
      transform:scale(.8) translateY(1000px);
      opacity:0;
      }
      100% {
      transform:scale(1) translateY(0px);
      opacity:1;
      }
  }
@keyframes growDown {
  0% {
    transform: scaleY(0)
}
80% {
    transform: scaleY(1.1)
}
100% {
    transform: scaleY(1)
}
  }

.mg-left-10{
  margin-left: 10px;;
}

@media (max-width: 1256px){
  .logo{
    width: 150px;
  }
}

@media (max-width: 775px){
  .container, .container-sm {
    max-width: 700px !important;
  }
}

@media (max-width: 700px){
  .container, .container-sm {
    max-width: 700px !important;
  }
}

@media (min-width: 576px){
  h2 {
      font-size: 2.25rem !important;
      line-height: 1.5;
  }

}
@media (max-width: 476px){
  .footer-links{
    flex-direction: column;
    align-items: flex-start !important;
  }

  .footer-links .mg-left-10{
    margin-left: 0px;
    
  }

}







