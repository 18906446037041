.membership-container{
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
}

.each-membership, .each-credit{
  padding: 2rem;
  border-radius: 6px;
  width:19%;
  text-align:center;
  background-color: var(--main-color);;
  transition: .3s ease!important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  height:25rem;
  margin-bottom:1.5rem;
}

.each-credit{
  height:16rem;
}

.card-price{
  font-size:1.2rem;
}

.each-membership:hover, .each-credit:hover{
  background-image: linear-gradient(to bottom right, var(--main-link-button-color) , var(--main-color));
  cursor:pointer;
}

.mebership-button{
  padding: 1rem 0 0 0;
}


.mebership-button button{
  padding: 0.3rem 2rem;
}


.membership-button{
  display: flex;
  width: 100%;
  justify-content: center;
}

.membership-table {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--main-color);;
  border-radius: 10px;
  padding: 15px;
}
/* 
.membership-table > * {
  width: 210px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #E6E6E6;
  max-width: calc((100% - 5px*5)/6);
} */



.membership-table .each-membership-new{
  width: 210px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
      border-radius: 10px;
    /* padding: 7px 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #E6E6E6;

} 

.each-membership-new .mebership-button{
  padding: 7px 12px;
}

.each-membership-new span{
  height: 40px;
}

.each-membership-new .header-benefit{
  height: 75px;
  font-size: 1.2rem;
  text-align: center;
  /* background-color: #9b8667; */
  width: 100%;
  /* width: 210px; */
}
/* membership-table .card-price:before */
.membership-table .prom-off, .checkout-details .prom-off{
  content: "40% OFF";
  display: inline-block;
  background-color: var(--main-link-button-color) ; /* Replace with your desired background color */
  color: #ffffff; /* Replace with your desired text color */
  padding: 0 10px; /* Adjust padding as desired */
  border-radius: 3px; /* Adjust border radius as desired */
  margin-left: 10px; /* Adjust margin as desired */
  font-size: 0.8rem;
  height: 20px;
  margin-right: 5px;
  
}

.prom-off{
  display: inline-block;
  margin-left: 10px;
  background-color: var(--main-link-button-color) ; /* Replace with your desired background color */
  color: #ffffff; /* Replace with your desired text color */
  padding: 0 10px; /* Adjust padding as desired */
  border-radius: 3px; /* Adjust border radius as desired */
  font-size: 0.8rem;
  height: 20px;
}


@media (max-width: 1400px){
  .membership-table{
    overflow: auto;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}

@media (max-width: 996px){
  .each-membership,.each-credit{
    width:48%;
  }

.membership-table:not(:first-child) > * {
    flex: 0 0 200px;
    margin: 5px 3px; 
}

}

@media (max-width: 576px){
  .each-membership,.each-credit{
    width:100%;
  }

  .membership-table div:first-child{
    min-width: 125px;
    width: 150px;
  }
}