.nav{
  position: sticky;
  top: 0;
}

.notification-new {
  background-color: #fff;
  color: var(--main-link-button-color);
  text-decoration: none;
  padding: 4px 10px !important;
  position: relative;
  display: inline-block;
  border-radius: 6px;
}

.notification-new:hover {
  color: var(--main-link-button-color);
}

.notification-new .badge-new {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0px 6px;
  border-radius: 50%;
  background: var(--main-link-button-color);
  color: white;
}


.header-section {
  background: var(--main-color);
  box-shadow: 0 0 10px rgb(255 255 255 / 10%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 999;
}

.header-wrapper, .menu-area, .menu-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-wrapper{
  height: 80px;
}

.menu-links li a{
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 15px 22px;
  text-transform: capitalize;
  text-decoration: none;
}
/* .menu-links li a:hover{
  color: var(--main-link-button-color);
} */

.menu-links{
  margin: 0 2rem 0px 0px;
}

.login, .signup{
  padding: 10px;
  text-decoration: none !important;
  border-radius: 100px;
  font-size: 18px;

}

.signup{
  background-color: var(--main-link-button-color);
  color:white;
  margin-left: 10px;
}




.login:hover{
 color: var(--main-link-button-color);
}
.signup:hover{
  color: #fff
}

.mobile-menu-area{
  display: none;
}

.hover-scale{
  transform: scale(1.1);
}



.dropdown-lng {
  position: relative;
  display: inline-block;
  font-size: 1rem;
}

.dropdown-lng-content {
  display: none;
  position: absolute;
  left: -5px;
  min-width: 35px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  background-color: var(--second-color);
}

.dropdown-lng:hover .dropdown-lng-content {
  display: block;
}

.item-lng{
  width:45px;
  padding: 5px;
  transition: .3s ease!important;
}

.item-lng:hover{
  background:var(--main-link-button-color) ;
}
.img-lng{

  width:35px;
  height:28px;
  border-radius: 2px;
  cursor:pointer;
}


.notification{
  position:relative;
  font-size:1rem;
}

.notification-content{
  position:absolute;
  background-color: var(--main-color);
  transition: all 0.3s ease;
  animation: growDown 300ms ease-in-out forwards;
  color: white;
  border-radius:6px;
  top: 45px;
  width: 295px;
  height: 175px;
  overflow: auto;
}

.each-notification:hover{
  color: var(--main-color);
  background-color: white;
}

.each-notification{
  color: #fff;
  font-size: 0.8rem;
  width:100%;
  padding: 0.5rem 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.notification-time{
  font-size: 0.6rem;

}


.icon-link{
  padding: 5px;
  margin-right: 5px;
  color: white !important;
}

.icons-header{
  display: flex;
  align-items: center;
  margin-right: 25px;
}

#inactive-chat{
  display: block;
  right: 0;
  bottom: 0;
  direction: ltr;
  position: fixed;
  z-index: 3000;
  margin: 0 14px 0 0;
  background-color: #56a8e3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
  outline: none;
  padding-top: 6px;
  padding-bottom: 4px;
  width: 249px;
  min-width: 230px;
  cursor: pointer;
}

#inactive-chat span{
  margin-left: 5px;
}

.icons-header div a:first-child{
  margin-right: 10px;
}

.icon-notification{
  font-size: 1.3em;
  padding: 2px 10px !important;
}

.no-notif{
  height:65px !important;
}

.active-icon{
  background-color: var(--main-link-button-color);
  padding: 7px 15px;
  border-radius:6px;

}
.icon-link svg{
  vertical-align: initial;
}



@media (max-width: 992px){
  .active{
    position: absolute;
    top: 100px;
    width: 100%;
    display: block !important;
    left: 0;
    transition: 0.5s ease-in-out;
  }

  .notification-new{
    margin-right: 15px;
  }
  

 .menu-area,.no-mobile{
    display: none;
 }

 .notification-content{
  right: -4px;
 }

 .menu-links{
   display: block;
   font-size: 25px;
   padding: 0;
   width: 100%;

 }
 .menu-links li a, .login, .signup{
  display: block;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 700;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--main-color);
  color: white !important;
 }


.mobile-icons{
  display:flex;
}
 .main-btn-header{
  display: block;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--main-color);
  color: white !important;
  margin:0;
  text-align: start;
}

.menu-btn .login i, .menu-btn .signup i{
  margin-left: 10px;
}

 .menu-links li a:hover,.login:hover, .signup:hover, .main-btn-header:hover{
  /* background: rgba(223, 49, 77); */
  background: var(--main-color);
 }
 .mobile-menu-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  
  /* font-size: 40px; */

}



.mobile-menu-area .burger{
  font-size: 2.5rem;
}

.menu-btn{
  display: grid;
  
}

}

@media (max-width: 400px){



 .notification-content{
  right: -100px;
 }




 }

